<script setup>
import { transl } from "@/composables/useTranslation";

const props = defineProps(["modelValue", "items", "location"]);
const emit = defineEmits(["update:modelValue", "update:location", "change"]);

// dialog
const dialog = ref(false);

watchEffect(() => (dialog.value = props.modelValue));
watchEffect(() => emit("update:modelValue", dialog.value));

function select(locationValue) {
  emit("update:location", locationValue);
  emit("change", locationValue);
  dialog.value = false;
}
</script>
<template>
  <v-dialog
    v-model="dialog"
    :options="{
      width: '100%',
      maxWidth: '50vh',
      maxHeight: '80vh',
      style: {
        borderRadius: '10px',
      },
    }"
  >
    <div class="header">
      <v-toolbar class="justify-left">
        <v-btn @click="dialog = false" icon class="mr-1">
          <v-svg src="/icons/close-svgrepo-com.svg" :options="{ width: '20px' }"></v-svg>
        </v-btn>
        <div class="section-title">
          <div>{{ transl("Texts:Select region") }}</div>
        </div>
      </v-toolbar>
    </div>
    <div class="content">
      <div v-for="location in items" @click="select(location)" class="region-item">
        {{ location?.location_path }}
      </div>
      <v-empty v-if="!items?.length"></v-empty>
      <div class="mb-10"></div>
    </div>
  </v-dialog>
</template>
<style scoped>
.content {
  overflow-y: auto;
}

.region-item {
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 50px;
}

.region-item:hover {
  background-color: var(--item-hover, #efefef);
}
</style>
~/composables/useTranslation
